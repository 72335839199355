



























































































import Vue from "vue";
import "weui";
import { Empty, List, PullRefresh, Sticky, Search, Image, Icon, Toast } from "vant";

export default Vue.extend({
  components: {
    [Empty.name]: Empty,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Sticky.name]: Sticky,
    [Search.name]: Search,
    [Image.name]: Image,
    [Icon.name]: Icon
  },
  data() {
    return {
      page: 0,
      mode: 0,
      cabinetRoomId: this.$route.query.cabinetRoomId || "",
      list: [],
      day: 0,
      showBusiness: false,
      loadFinished: false,
      refreshing: false,
      empty: false,
      keyword: this.$route.query.keyword || "",
      searchForm: {
        keyword: this.$route.query.keyword || ""
      }
    };
  },
  watch: {
    mode: "onRefresh",
    day: "onRefresh"
  },
  created() {
    this.$readyWx(this.$axios, ["scanQRCode"]);
  },
  methods: {
    scanCode() {
      this.$scanCode(content => {
        const [head, code] = content.split("?c=");
        if (!code) {
          Toast.fail("无效的二维码");
        }
        if (!head.endsWith("cabinet") && !head.endsWith("room") && !head.startsWith("http://app.hzdk.com/d")) {
          Toast.fail("二维码格式错误");
        }
        this.keyword = code;
        this.search();
      });
    },
    isSearchLocked() {
      return this.$route.query.cabinetRoomId && true;
    },
    search() {
      this.searchForm.keyword = this.keyword;
      this.onRefresh();
    },
    onRefresh() {
      this.refreshing = true;
      this.page = 0;
      this.list = [];
      this.onLoad().finally(() => {
        this.refreshing = false;
      });
    },
    onLoad() {
      return this.getList()
        .then(([data, requestData]) => {
          this.loadFinished = data.list.length < requestData.size;
          this.empty = data.list.length == 0 && requestData.page == 0;
        })
        .catch(() => {
          this.loadFinished = true;
          this.empty = true;
        });
    },
    getList() {
      let status;
      if (this.mode == 0) {
        status = [0, 1, 2, 3];
      } else {
        status = [-1, -2, -3, -4, -5, 4];
      }
      const size = 10;
      const data = {
        status: status,
        cabinetRoomId: this.cabinetRoomId,
        day: this.day,
        keyword: this.searchForm.keyword,
        page: this.page++,
        size: size
      };
      return this.$axios.post("/api/manage/battery/chargeOrder/list", data).then(e => {
        const res = e.data;
        this.showBusiness = res.data.showBusiness;
        const newList = res.data.list;
        this.list = this.list.concat(newList);
        return Promise.resolve([res.data, data]);
      });
    },
    toDetail(orderId: number) {
      this.$router.push("/battery-order/detail/" + orderId);
    }
  }
});
